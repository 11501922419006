import { Text, Image, HStack, GridItem, Grid } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../utils";
import { Profile } from "../../models";
import { MdMailOutline } from "react-icons/md";
import { FaMapMarkerAlt, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { GetOpeningHoursForSummary } from "./opening-hours-detail";
import { BsPersonFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";


interface Props {
    vendorId: string
}

export function VendorSummary(props: Props) {
    const navigate = useNavigate();
    const [vendorSummary, setVendorSummary] = useState<Profile>({})
    useEffect(() => {
        const fetch = async () => {
            const url = `${process.env.REACT_APP_API}/api/profileinfo/getVendorInfo?vendorId=${props.vendorId}`;
            const respone = await fetchWithAuth(url, "", { method: 'GET' })
            const res = await respone.json()
            setVendorSummary(res)
        }
        fetch().catch(console.error)
    }, [props.vendorId])

    if (vendorSummary.userType === 1) {
        return (
            <Grid gridRowGap={3} margin="10px">
                <GridItem onClick={() => navigate(`/vendor-page/${props.vendorId}`)} cursor="pointer">
                    <HStack>
                        <Image boxSize="55" src={`${process.env.REACT_APP_API}/${vendorSummary.logoUrl}`} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = "/images/missing_car.jpg"; }} />
                        <Text fontWeight="bold">{vendorSummary.companyName}</Text>
                    </HStack>
                </GridItem>
                <GridItem  >
                    <HStack>
                        <FaMapMarkerAlt />
                        <Text>{vendorSummary.address}</Text>
                    </HStack>
                </GridItem>
                <GridItem hidden={!vendorSummary.usePhoneNumber && !vendorSummary.useWhatsappNumber}>
                    <Text as="i" fontSize="sm">Contatti:</Text>
                    <HStack gap={5}>
                        <HStack hidden={!vendorSummary.usePhoneNumber}>
                            <FaPhoneAlt />
                            <Text>
                                <a href={"tel:" + vendorSummary.phoneNumber}>
                                    {vendorSummary.phoneNumber}
                                </a>
                            </Text>
                        </HStack>
                        <HStack hidden={!vendorSummary.useWhatsappNumber}>
                            <FaWhatsapp />
                            <Text >
                                <a href={"https://wa.me/+39" + vendorSummary.whatsappPhoneNumber} target="_blank" rel="noopener noreferrer">{vendorSummary.whatsappPhoneNumber}</a>
                            </Text>
                        </HStack>
                    </HStack>
                </GridItem>
                <GridItem hidden={!vendorSummary.useEmail}>
                    <HStack>
                        <MdMailOutline />
                        <Text>
                            <Link to={"mailto:" + vendorSummary.email} target="_blank" rel="noopener noreferrer">
                                {vendorSummary.email}
                            </Link>
                        </Text>
                    </HStack>
                </GridItem>
                <GetOpeningHoursForSummary workingHours={vendorSummary.workingHours} />
            </Grid>
        )
    }
    else {
        return (
            <Grid gridRowGap={3} margin="10px">
                <GridItem >
                    <HStack>
                        <BsPersonFill />
                        <Text fontWeight="bold" >Venditore Privato</Text>
                    </HStack>
                </GridItem>
                <GridItem  >
                    <HStack>
                        <FaMapMarkerAlt />
                        <Text>{vendorSummary.address}</Text>
                    </HStack>
                </GridItem>
                <GridItem hidden={!vendorSummary.usePhoneNumber && !vendorSummary.useWhatsappNumber}>
                    <Text as="i" fontSize="sm">Contatti personali</Text>
                    <HStack gap={5}>
                        <HStack hidden={!vendorSummary.usePhoneNumber}>
                            <FaPhoneAlt />
                            <Text ><a href={"tel:" + vendorSummary.phoneNumber}>
                                {vendorSummary.phoneNumber}
                            </a></Text>
                        </HStack>
                        <HStack hidden={!vendorSummary.useWhatsappNumber}>
                            <FaWhatsapp />
                            <Text >
                                <a href={"https://wa.me/+39" + vendorSummary.whatsappPhoneNumber} target="_blank" rel="noopener noreferrer">{vendorSummary.whatsappPhoneNumber}</a>
                            </Text>
                        </HStack>
                    </HStack>
                </GridItem>
                <GridItem hidden={!vendorSummary.useEmail}>
                    <HStack>
                        <MdMailOutline />
                        <Text><Link to={"mailto:" + vendorSummary.email} target="_blank" rel="noopener noreferrer">
                            {vendorSummary.email}
                        </Link></Text>
                    </HStack>
                </GridItem>
            </Grid>
        )
    }
}