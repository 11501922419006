import { useParams, Link } from "react-router-dom";
import { PageContainer } from "../../components";
import { Grid, GridItem, Text, Image, Flex, Center, Stack, Divider, HStack, TableContainer, Table } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../utils";
import { Profile } from "../../models";
import { FaMapMarkerAlt, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import { TimeIcon } from "@chakra-ui/icons";
import { DayOfWeek } from "../../components/opening-hours-table/day-of-week";
import { WorkingHours } from "../../models/working-hours";
import { BingMap } from "../../components/bing-map";
import { VendorsCars } from "../../components/vendor-summary/vendors-cars";
import { Helmet } from "react-helmet";

export function VendorPage() {
    const { id } = useParams();
    const [vendorSummary, setVendorSummary] = useState<Profile>({})
    const [backgroundIsToHide] = useState<boolean>(vendorSummary.backgroundUrl !== undefined && vendorSummary.backgroundUrl !== null && vendorSummary.backgroundUrl !== "" && vendorSummary.logoUrl !== undefined && vendorSummary.logoUrl !== null && vendorSummary.logoUrl !== "")
    const [pageToShow, setPageToShow] = useState<string>("Informations")

    useEffect(() => {
        const fetch = async () => {
            const url = `${process.env.REACT_APP_API}/api/profileinfo/getVendorInfo?vendorId=${id}`;
            const respone = await fetchWithAuth(url, "", { method: 'GET' })
            const res = await respone.json()
            setVendorSummary(res)
        }
        fetch().catch(console.error)
    }, [id])

    function GetOpeningHoursAndStatus(workingHour: WorkingHours) {
        if (!workingHour.isClosed) {
            if (workingHour.morningOpeningHour !== "" && workingHour.eveningOpeningHour !== "") {
                return (
                    <Text>
                        {workingHour.morningOpeningHour}-{workingHour.morningClosingHour} | {workingHour.eveningOpeningHour}-{workingHour.eveningClosingHour}
                    </Text>
                )
            }
            else if (workingHour.morningOpeningHour !== "") {
                return (
                    <Text>
                        {workingHour.morningOpeningHour}-{workingHour.morningClosingHour}
                    </Text>
                )
            }
            else {
                return (
                    <Text>
                        {workingHour.eveningOpeningHour}-{workingHour.eveningClosingHour}
                    </Text>
                )
            }
        }
        else {
            return (
                <Text color="grey">Chiuso</Text>
            )
        }
    }

    return (
        <PageContainer >
            <Helmet>
                <title>Part Scout | Pagina venditore</title>
                <meta name="description" content="PartScout a site for all your car rebuild needs" />
                <meta property="og:title" content="Part Scout | Pagina venditore" />
                <meta property="og:description" content="PartScout a site for all your car rebuild needs" />
                <meta property="og:image" content="%PUBLIC_URL%/logo512.png" />
            </Helmet>
            <Flex style={{ maxWidth: "1100px", paddingLeft: "40px", paddingRight: "40px", marginRight: "auto", marginLeft: "auto", marginTop: "40px", justifyContent: "space-between", alignItems: "center" }}>
                <Image maxHeight="150px" maxWidth="150px" src={`${process.env.REACT_APP_API}/${vendorSummary.logoUrl}`} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = "/images/missing_car.jpg"; }} />
                <Center marginLeft="20px">
                    <Text as='u' cursor="pointer" onClick={() => setPageToShow("Informations")}>Informazioni sul venditore</Text>
                </Center>
                <Center marginLeft="20px">
                    <Text as='u' cursor="pointer" onClick={() => setPageToShow("Offers")}>Le nostre offerte</Text>
                </Center>
                {/* <Center >
                    <Text as='u' cursor="pointer">Recensioni</Text>
                </Center> */}
            </Flex>
            <Flex style={{ maxWidth: "1100px", marginRight: "auto", marginLeft: "auto", marginTop: "10px" }}>
                <Divider />
            </Flex>
            <Flex style={{ maxWidth: "1100px", marginRight: "auto", marginLeft: "auto", marginTop: "10px", marginBottom: "40px" }} hidden={pageToShow !== "Informations"}>
                <Stack width="100%">
                    <Image style={{ maxHeight: "600px", width: "100%" }} hidden={backgroundIsToHide} src={`${process.env.REACT_APP_API}/${(vendorSummary.backgroundUrl !== undefined && vendorSummary.backgroundUrl !== null && vendorSummary.backgroundUrl !== "") ? vendorSummary.backgroundUrl : vendorSummary.logoUrl}`} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = "/images/missing_car.jpg"; }} />
                    <Divider hidden={backgroundIsToHide} />
                    <Text fontWeight="bold" > Informazioni sul venditore:</Text>
                    <Text>{vendorSummary.companyInformations}</Text>
                    <Divider />
                    <Center>
                        <Text fontWeight="bold" > Contatti:</Text>
                    </Center>
                    <HStack flexDir={{ base: 'column', md: 'row' }} justify="space-between">
                        <Grid gridRowGap={3} margin="10px">
                            <GridItem >
                                <Text fontWeight="bold">{vendorSummary.companyName}</Text>
                            </GridItem>
                            <GridItem  >
                                <HStack>
                                    <FaMapMarkerAlt />
                                    <Text>{vendorSummary.address}</Text>
                                </HStack>
                            </GridItem>
                            <GridItem hidden={!vendorSummary.usePhoneNumber && !vendorSummary.useWhatsappNumber}>
                                <Text as="i" fontSize="sm">Numeri di telefono:</Text>
                                <HStack gap={5}>
                                    <HStack hidden={!vendorSummary.usePhoneNumber}>
                                        <FaPhoneAlt />
                                        <Text><a href={"tel:" + vendorSummary.phoneNumber}>{vendorSummary.phoneNumber}</a></Text>
                                    </HStack>
                                    <HStack hidden={!vendorSummary.useWhatsappNumber}>
                                        <FaWhatsapp />
                                        <Text><a href={"https://wa.me/+39" + vendorSummary.whatsappPhoneNumber} target="_blank" rel="noopener noreferrer">{vendorSummary.whatsappPhoneNumber}</a></Text>
                                    </HStack>
                                </HStack>
                            </GridItem>
                            <GridItem hidden={!vendorSummary.useEmail}>
                                <HStack>
                                    <MdMailOutline />
                                    <Text>
                                        <Link to={"mailto:" + vendorSummary.email} target="_blank" rel="noopener noreferrer">
                                            {vendorSummary.email}
                                        </Link>
                                    </Text>
                                </HStack>
                            </GridItem>
                            <GridItem>
                                <HStack>
                                    <TimeIcon color="green" boxSize={16} />
                                    <TableContainer id="HoursTable" marginTop="20px" width="min-content">
                                        <Text fontWeight="bold" >Orari:</Text>
                                        <Table size="lg" style={{ width: "100%" }}>
                                            <tbody>
                                                {vendorSummary.workingHours?.map((workingHour, index) => {
                                                    return (
                                                        <tr key={index} >
                                                            <DayOfWeek dayOfWeek={workingHour.dayNumber} />
                                                            <td style={{ paddingLeft: "20px" }}><GetOpeningHoursAndStatus {...workingHour} /></td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </TableContainer>
                                </HStack>
                            </GridItem>
                        </Grid>
                        <BingMap width="100%" height="400px" address={vendorSummary.address ?? ""} />
                    </HStack>
                </Stack>
            </Flex>
            <VendorsCars vendorId={id ?? ""} hidden={pageToShow !== "Offers"} />
        </PageContainer >
    );
}
